
import React, { useEffect, useState, useCallback, useRef } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Scanner } from '@yudiel/react-qr-scanner'
import DeviceDetector from "device-detector-js"
import { useToast } from "@/hooks/use-toast"
import { ToastAction } from "@radix-ui/react-toast"

import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer"
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { faBarcodeRead, faScannerTouchscreen } from "@fortawesome/pro-light-svg-icons"
import { useAudioPlayer } from 'react-use-audio-player'
import { Text } from "./text"
import { Checkbox } from "./ui/checkbox"

export function BarcodeScan({ children, onScan, isOpen = false, notCloseable, onOpenChange, autoScan, barcodeTypeInfo, boundary, toastError = false, debug, closeOnSuccess = true, customButtons, customOptions }: { children?: React.ReactNode, onScan: (scan: string) => any, isOpen?: boolean, notCloseable?: Boolean, onOpenChange?: any, autoScan?: any, barcodeTypeInfo?: string, boundary?: any, toastError?: any, debug?: string, closeOnSuccess?: boolean, customButtons?: Array<any>, customOptions?: Array<any> }) {
    const i18nPath                                                    = "ui.barcodescan"
    const [open, setOpen]                                             = useState(isOpen)
    const [useCamera, setUseCamera]                                   = useState(false)
    const [scan, setScan]                                             = useState("")
    const [autoScanContent, setAutoScanContent]                       = useState("")
    const [feedbackClass, setFeedbackClass]                           = useState("")
    const [feedbackMessage, setFeedbackMessage]                       = useState({}) as any
    const [fallbackScan, setFallbackScan]                             = useState(false)
    const [allowCameraScan, setAllowCameraScan]                       = useState(false)
    const [waitingForScannerInterface, setWaitingForScannerInterface] = useState<number | boolean>(false)
    const successSound                                                = useAudioPlayer()
    const errorSound                                                  = useAudioPlayer()
    const deviceDetector                                              = new DeviceDetector()
    const autoScanTimeout                                             = useRef<ReturnType<typeof setTimeout> | null>(null)
    const { toast }                                                   = useToast()

    const honeywell = {
        scanner: null
    } as any

    useEffect(() => {
        successSound.load('/assets/sounds/success.mp3')
        errorSound.load('/assets/sounds/error.mp3')
    }, [])

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const onSuccess = useCallback(() => {
        successSound.play()
        setFeedbackClass("bg-green-500/20")
        if (closeOnSuccess) {
            setOpen(false)
        }
        else {
            setTimeout(() => {
                setFeedbackClass("")
            }, 2000)
        }
    }, [successSound])

    const onError = useCallback((response: any) => {
        errorSound.play()
        if (toastError) {
            toast({
                variant: "destructive",
                title: toastError['title'],
                description: toastError['description'],
            })
        }
        setScan("")
        setFeedbackClass("bg-red-500/20")
        setFeedbackMessage(response)
    }, [successSound])

    useEffect(() => {
        if (isOpen) {
            if (boundary?.error) {
                onError(boundary)
            }
            else if (boundary){
                onSuccess()
            }
        }
        return () => setFeedbackClass("")
    }, [boundary])
    
    useEffect(() => {
        const keyBoardScanEventListener = (e: KeyboardEvent) => {
            e.preventDefault()
            if (!open && autoScan.length > 0 && e.key !== "Enter") {
                setAutoScanContent((autoScanContent) => {
                    const newAutoScanContent = autoScanContent + e.key
                    if (autoScan.filter((keyword: string) => ((newAutoScanContent).toLowerCase().toLowerCase().startsWith(keyword.toLowerCase()))).length > 0) {
                        setScan(newAutoScanContent)
                    } 
                    return newAutoScanContent
                })
                clearTimeout(autoScanTimeout.current)
                autoScanTimeout.current = setTimeout(() => {
                    onError(boundary)
                    setAutoScanContent('')
                    setScan('')
                }, 500)
                return
            }
            if (e.key === "Enter" && scan.length > 0) {
                clearTimeout(autoScanTimeout.current)
                onScan(scan)                
                setAutoScanContent('')
                setScan("")
            }
            else if (e.key !== "Enter") {
                setScan((scan) => scan + e.key)
            }
        }
        if ((open && fallbackScan) || autoScan) {
            document.addEventListener("keypress", keyBoardScanEventListener)
        }
        else {
            document.removeEventListener("keypress", keyBoardScanEventListener)
        }

        return () => {
            document.removeEventListener("keypress", keyBoardScanEventListener)
        }
    }, [open, scan, autoScan, autoScanContent, fallbackScan])

    
    const honeywellEnable = useCallback((enabled: boolean) => {
        const honeywellScanEventListener = (data: any, type: any, time: any) => {
            if ((open || autoScan) && data) {
                onScan(data)
                setScan("")
            }
        }
        if (enabled) {
            const connectBarcodeReader = (count: number) => {
                if (honeywell.scanner || count > 100) {
                    return
                }

                if (!waitingForScannerInterface) {
                    setWaitingForScannerInterface(count)
                }

                if (typeof BarcodeReader !== 'undefined') {
                    honeywell.scanner = new BarcodeReader(null, (result: any) => {
                        if (result.status !== 0) {
                            setFallbackScan(true)
                        }
                        else {
                            setWaitingForScannerInterface(false)
                            honeywell.scanner.enableTrigger(true, () => {
                                honeywell.scanner.addEventListener("barcodedataready", honeywellScanEventListener, false)
                            })
                        }

                    })
                }
                else {
                    setTimeout(() => {
                        connectBarcodeReader(count + 1)
                    }, 100)
                }
            }

            connectBarcodeReader(1)
        } else if (honeywell.scanner) {
            setWaitingForScannerInterface(false)
            honeywell.scanner.enableTrigger(false, () => {
                honeywell.scanner.removeEventListener("barcodedataready", honeywellScanEventListener)
                honeywell.scanner.close()
            })
        }
        else {
            setFallbackScan(true)
        }
    }, [open])

    useEffect(() => {
        const device = deviceDetector.parse(navigator.userAgent)
        if (
            !honeywell.scanner &&
            device.os && device.os.name == "Android" &&
            window.screen.availWidth + "x" + window.screen.availHeight === '360x640'
        ) {
            honeywellEnable(open || autoScan?.length > 0)
        }
        else if (device.os.name == "iOS") {
            setAllowCameraScan(true)
        }
        else {
            setFallbackScan(true)
        }

        return () => {
            honeywellEnable(false)
        }
    }, [open])

    useEffect(() => {
        const pasteEventListener = (e: ClipboardEvent) => {
            e.preventDefault()

            const clipboardData = e.clipboardData || window.clipboardData
            onScan(clipboardData.getData("text"))
            setScan("")
        }

        if (open && fallbackScan) {
            document.addEventListener("paste", pasteEventListener)
        }
        else {
            document.removeEventListener("paste", pasteEventListener)
        }

        return () => document.removeEventListener("paste", pasteEventListener)
    }, [open, scan, fallbackScan])

    
    return (
        <>
            <Dialog
                open={(waitingForScannerInterface > 1)}
            >
                <DialogContent
                    className="[&>button]:hidden"
                    onInteractOutside={(e) => {
                        e.preventDefault();
                    }}
                >
                    <DialogHeader>
                        <DialogTitle>
                            <Text path={i18nPath}>waitingForScannerInterface</Text>
                            {waitingForScannerInterface}
                        </DialogTitle>
                        <DialogDescription>
                            {waitingForScannerInterface >= 50 && (
                                <Button
                                    onClick={() => {
                                        window.location.reload()
                                    }}
                                    variant="outline"
                                >
                                <Text path="global.button">cancel</Text>
                            </Button>
                            )}
                            {waitingForScannerInterface < 50 && (
                                <FontAwesomeIcon
                                    className="mt-4"
                                    icon={faScannerTouchscreen}
                                    size="3x"
                                    bounce
                                />
                            )}
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog>

            <Drawer
                open={open && !!!waitingForScannerInterface}
                onOpenChange={(open) => {
                    if (notCloseable) {
                        return
                    }
                    setOpen(open)
                    setFeedbackClass("")
                    if (onOpenChange) {
                        onOpenChange(open)
                    }
                    if (!autoScan) {
                        setScan("")
                    }
                }}
            >
                <DrawerContent className={feedbackClass}>
                    <DrawerHeader className="flex flex-col items-center">
                        <DrawerTitle className="uppercase"><Text path={i18nPath}>title</Text></DrawerTitle>
                        <DrawerDescription
                            className="animate-pulse"
                        >
                            {barcodeTypeInfo && (
                                <span className="uppercase bottom-8 font-extrabold text-lg text-primary"><Text>{barcodeTypeInfo}</Text></span>
                            )}
                        </DrawerDescription>
                    </DrawerHeader>

                    {(children && !useCamera) && (
                        <div className="flex flex-col items-center">
                            <div className="rounded-md  max-w-72 border px-4 py-2 font-mono text-sm shadow-sm relative">
                                {children}
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col items-center relative text-muted-foreground">
                        {useCamera && (
                            <div
                                className="max-w-96 max-h-96 w-full h-full"
                            >
                                <Scanner
                                    onScan={(detectedCodes) => {
                                        if (detectedCodes.length > 0) {
                                            const code = detectedCodes[0].rawValue
                                            setUseCamera(false)
                                            onScan(code)
                                        }
                                    }}
                                    paused={!useCamera}
                                    onError={(error: any) => {
                                        setUseCamera(false)
                                    }}
                                    constraints={{
                                        facingMode: 'environment'
                                    }}
                                    styles={{
                                        container: {
                                            marginTop: "1rem",
                                        },
                                    }}
                                    formats={[
                                        'qr_code',
                                        'micro_qr_code',
                                        'rm_qr_code',
                                        'maxi_code',
                                        'pdf417',
                                        'aztec',
                                        'data_matrix',
                                        'matrix_codes',
                                        'dx_film_edge',
                                        'databar',
                                        'databar_expanded',
                                        'codabar',
                                        'code_39',
                                        'code_93',
                                        'code_128',
                                        'ean_8',
                                        'ean_13',
                                        'itf',
                                        'linear_codes',
                                        'upc_a',
                                        'upc_e'
                                    ]}
                                    components={{
                                        audio: false,
                                        onOff: false,
                                        torch: false,
                                        zoom: false,
                                        finder: false,
                                    }}
                                />
                            </div>
                        )} 
                    </div>
                    
                    <DrawerFooter className="flex flex-col items-center justify-center">
                            {customOptions && customOptions.length > 0 && customOptions.map((option: any, key: number) => (
                                <div key={key} className="flex items-left space-x-2 w-60">
                                    <Checkbox
                                        id={`custom-option-id-${key}`}
                                        checked={option.checked}
                                        onCheckedChange={(checked) => {
                                            if (option.onCheckedChange) {
                                                option.onCheckedChange(checked)
                                            }
                                        }}
                                    />
                                    <label
                                        htmlFor={`custom-option-id-${key}`}
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        {option.name}
                                    </label>
                                </div>
                            ))}

                        
                    </DrawerFooter>
                    <DrawerFooter className="flex flex-row items-center justify-center">
                        {!notCloseable && (
                            <Button
                                onClick={() => {
                                    if (onOpenChange) {
                                        onOpenChange(false)
                                    }
                                    else {
                                        setOpen(false)
                                    }
                                }}
                                variant="outline"
                            >
                                <Text path="global.button">cancel</Text>
                            </Button>
                        )}
                        {allowCameraScan && (
                            <Button onClick={() => setUseCamera(!useCamera)} variant="outline">
                                {useCamera && (
                                    <Text path={i18nPath}>cameraStop</Text>
                                )}
                                {!useCamera && (
                                    <Text path={i18nPath}>cameraUse</Text>
                                )}
                            </Button>
                        )}
                        {customButtons && customButtons.length > 0 && customButtons.map((entry: any) => (
                            <Button onClick={entry.onClick} key={entry.name} variant={entry.variant}>{entry.name}</Button> 
                        ))}
                    </DrawerFooter>
                </DrawerContent>
                <AlertDialog
                    open={!!feedbackMessage}
                    onOpenChange={() => {
                        setFeedbackMessage({})
                        setFeedbackClass("")
                    }}
                >
                    {feedbackMessage?.errorCode && (
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>{feedbackMessage.errorCode}</AlertDialogTitle>
                                <AlertDialogDescription>
                                    <Text>{feedbackMessage.errorCode}</Text>
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>
                                    <Text path="global.button">continue</Text>
                                </AlertDialogCancel>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    )}
                </AlertDialog>
            </Drawer>
        </>
    )
}
